<script setup>
import "~/assets/css/index.css";
import { loggedIn } from "~/composables/useLogin";
import { useTranslation } from '@/composables/useTranslation'

const { fetchTransl, setLocale } = useTranslation();

const menuLeftDialog = ref(false);
const menuRightDialog = ref(false);
const registrSuccessDialog = ref(false);
const payDialog = ref(false);
const oauthErrorDialog = ref(false);
const changePasswordDialog = ref(false);
const changePasswordDialogContact = ref(false);
const recoverPassDialog = ref(false);
const recoverPassSuccessDialog = ref(false);

const dialogSelectedTab = ref("login");
const selectedCourse = ref(null);

onGlobal("menu-left-mobile", () => {
  menuLeftDialog.value = true;
});

onGlobal("menu-right-mobile", () => {
  menuRightDialog.value = true;
});

onGlobal("change-password-dialog", ({ contact }) => {
  dialogSelectedTab.value = "login";
  menuRightDialog.value = false;
  changePasswordDialog.value = true;
  changePasswordDialogContact.value = contact;
});

onGlobal("registr", ({ course }) => {
  selectedCourse.value = course;
  menuRightDialog.value = true;
  dialogSelectedTab.value = "registr";
});

onGlobal("registr-success", () => {
  menuRightDialog.value = false;
  registrSuccessDialog.value = true;
});

onGlobal("pay-dialog", () => {
  payDialog.value = true;
});

onGlobal("recover-pass", () => {
  menuRightDialog.value = false;
  recoverPassDialog.value = true;
});

onGlobal("recover-pass-success", ({contact}) => {
  menuRightDialog.value = false;
  recoverPassDialog.value = false;

  recoverPassSuccessDialog.value = true;
});

onMounted(() => {
  if (localStorage.getItem("OAUTH_ERROR_USER_NOT_REGISTRED")) {
    oauthErrorDialog.value = true;
    localStorage.removeItem("OAUTH_ERROR_USER_NOT_REGISTRED");
  }
});

const seo = {
  metaTitle: "Bible courses platform",
  metaDescr:
    "Bibleawr.com — онлайн-платформа, где эксперты помогут вам глубже понять Библию и раскрыть ее тайны, чтобы вы смогли укрепить свою веру и достичь духовного роста.",
  metaImage: "https://bibleawr.com/landing/baner-desctop.png",
};

useServerSeoMeta({
  title: seo?.metaTitle,
  ogTitle: seo?.metaTitle,
  description: seo?.metaDescr,
  ogDescription: seo?.metaDescr,
  ogImage: seo?.metaImage,
});
</script>
<template>
  <V-Header></V-Header>
  <main>
    <slot></slot>
  </main>
  <v-footer></v-footer>
<!-- 
  <v-menu-mobile-left-dialog v-model:dialog="menuLeftDialog"></v-menu-mobile-left-dialog>

  <v-menu-mobile-right-dialog
    v-model:dialog="menuRightDialog"
    :selectedTab="dialogSelectedTab"
    :selectedCourse="selectedCourse"
  ></v-menu-mobile-right-dialog>

  <v-change-password-dialog
    v-model:dialog="changePasswordDialog"
    :contact="changePasswordDialogContact"
  >
  </v-change-password-dialog>

  <v-registr-success-dialog
    v-model:dialog="registrSuccessDialog"
  ></v-registr-success-dialog>

  <v-pay-dialog
    v-model:dialog="payDialog"
  ></v-pay-dialog>

  <v-user-recover-pass-dialog
    v-model:dialog="recoverPassDialog"
  ></v-user-recover-pass-dialog>

  <v-user-recover-pass-success-dialog
    v-model:dialog="recoverPassSuccessDialog"
  ></v-user-recover-pass-success-dialog>

  <v-user-inform-dialog v-model:dialog="oauthErrorDialog">
    <template #title>Ошибка входа</template>
    Вы не зарегистрированы на платформе <b>"Книга книг"</b>. Пройдите регистрацию, чтобы
    войти.
  </v-user-inform-dialog> -->
</template>
